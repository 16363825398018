<template>
  <div>
    <v-data-table
      :headers="this.$props.head"
      :items="this.$props.data"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      item-class="primary--text"
      :search="this.$props.search"
      mobile-breakpoint="1000"
      @page-count="pageCount = $event"
      @pagination="(item) => $emit('pageChange', item)"
    >
      <template v-slot:[`item.itemNo`]="{ item }">
        <label>{{ item.itemNo }}</label>
      </template>

      <template v-slot:[`item.list`]="{ item }">
        <v-checkbox
          v-if="item.list == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>
      <template v-slot:[`item.add`]="{ item }">
        <v-checkbox
          v-if="item.add == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>
      <template v-slot:[`item.del`]="{ item }">
        <v-checkbox
          v-if="item.del == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>
      <template v-slot:[`item.modify`]="{ item }">
        <v-checkbox
          v-if="item.modify == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>
      <template v-slot:[`item.row`]="{ item }">
        <v-checkbox
          v-if="item.row == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>
      <template v-slot:[`item.column`]="{ item }">
        <v-checkbox
          v-if="item.column == true"
          input-value="true"
          disabled
        ></v-checkbox>
        <v-checkbox v-else value disabled></v-checkbox>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30"
          >.....more
        </span>
      </template>
    </v-data-table>

    <div class="text-center mt-4">
      <v-row>
        <v-spacer />

        <v-col cols="4" class="mt-2">
          <v-pagination v-model="page" :length="pageCount" />
        </v-col>

        <v-spacer />

        <v-col cols="1" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="pageCount"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            min="1"
            v-model.number="page"
            label="Jump to page"
            type="number"
            @focus="$event.target.select()"
            @change="pageChange"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template> 

<script>
import serverConfig from "@/utils/serverConfig";
export default {
  name: "Functable",
  props: { head: Array, data: Array, search: String, dialog: Boolean },
  data() {
    return {
      blankIMG: serverConfig.blank_profile_img,
      imgUrl: serverConfig.img_url,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  methods: {
    editItem(item) {
      console.log(this.$store.getters.per);
    },
    pageChange() {
      if (this.page.length == 0 || this.page == "") {
        this.$toast.warning("Can not input Page 0 or empty");
        this.page = 1;
      }
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>