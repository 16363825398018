var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.$props.head,"items":this.$props.data,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-class":"primary--text","search":this.$props.search,"mobile-breakpoint":"1000"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"pagination":function (item) { return _vm.$emit('pageChange', item); }},scopedSlots:_vm._u([{key:"item.itemNo",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.itemNo))])]}},{key:"item.list",fn:function(ref){
var item = ref.item;
return [(item.list == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.add",fn:function(ref){
var item = ref.item;
return [(item.add == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.del",fn:function(ref){
var item = ref.item;
return [(item.del == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.modify",fn:function(ref){
var item = ref.item;
return [(item.modify == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.row",fn:function(ref){
var item = ref.item;
return [(item.row == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.column",fn:function(ref){
var item = ref.item;
return [(item.column == true)?_c('v-checkbox',{attrs:{"input-value":"true","disabled":""}}):_c('v-checkbox',{attrs:{"value":"","disabled":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.pageCount,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number"},on:{"focus":function($event){return $event.target.select()},"change":_vm.pageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=_vm._n($$v)},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }